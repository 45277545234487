<template>
  <v-card flat>
    <v-card-title>Dark Mode Switch</v-card-title>
    <v-card-subtitle>Do you like in the dark mode?</v-card-subtitle>
    <v-card-text>
      <v-switch v-model="$vuetify.theme.dark" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DarkMode",
  computed: {
    status() {
      return this.$vuetify.theme.dark;
    },
  },
  props: {
    submit: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    submit(e) {
      if (e) {
        this.$emit("success");
      }
    },
    status() {
      localStorage.setItem("dark", this.$vuetify.theme.dark);
    },
  },
};
</script>
