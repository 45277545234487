<template>
  <v-container>
    <v-row class="justify-center">
      <v-col :cols="mini ? 9 : 3" v-show="!mini || !setting">
        <v-card>
          <v-card-title>Settings</v-card-title>
          <v-card-subtitle>Configure your StarStart!</v-card-subtitle>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(i, j) in view_options"
                :key="j"
                @click="next(i)"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ i.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ i.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-show="!mini || setting" cols="9">
        <v-card flat>
          <v-card-title>App Settings</v-card-title>
          <v-card-text>
            <component
              :is="view"
              :init="true"
              :submit="submit"
              @success="close"
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="amber darken-3"
              dark
              depressed
              rounded
              @click="submit = true"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import Profile from "@/components/SettingProfile";
import DarkMode from "../components/SettingDarkMode.vue";

export default {
  name: "Setting",
  data: () => ({
    ok: false,
    submit: false,
    setting: false,
    view_id: "profile",
    view_options: [
      { id: "profile", name: "Profile", description: "How may I call you?" },
      {
        id: "dark-mode",
        name: "Dark Mode",
        description: "Do you like in the dark?",
      },
    ],
  }),
  computed: {
    view() {
      return this.view_id;
    },
    view_end() {
      return this.view_id >= this.view_options.length - 1;
    },
    mini() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  components: { Profile, DarkMode },
  methods: {
    next(i) {
      this.setting = true;
      this.view_id = i.id;
    },
    close() {
      this.ok = true;
      this.submit = false;
      this.setting = false;
      if (!this.mini) {
        this.$router.push("/");
      }
    },
  },
};
</script>
