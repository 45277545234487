<template>
  <v-card flat>
    <v-card-title>Profile</v-card-title>
    <v-card-subtitle v-if="init"> Initialize your profile. 😉 </v-card-subtitle>
    <v-card-subtitle v-else>
      Configure your profile on StarStart! 😛
    </v-card-subtitle>
    <v-card-text>
      <v-text-field
        v-if="init"
        v-model="nickname"
        label="How should I call you? (Nickname)"
        @keydown.enter="submit"
      />
      <v-text-field
        v-else
        v-model="nickname"
        label="Nickname"
        @keydown.enter="submit"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Profile",
  props: {
    init: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    submit: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    nickname: "",
  }),
  mounted() {
    if (this.$store.state.nickname) {
      this.nickname = this.$store.state.nickname;
    }
  },
  watch: {
    async submit(e) {
      if (!e || !this.nickname) return;
      const params = new URLSearchParams();
      params.set("nickname", this.nickname);
      const response = await this.$appClient.put("/pattern/profile", params);
      if (response.status === 201) {
        this.$store.commit("setNickname", this.nickname);
        setTimeout(() => this.$emit("success"), 100);
      }
    },
  },
};
</script>
